import * as React from "react";

import Layout from "@components/layout";
import SEO from "@components/seo";
import EventsComponent from "@components/Events";

const EventsPageBnb = ({ uri }) => (
    <Layout path={uri}>
      <SEO title="Upcoming Magyk Software Events" />
      <EventsComponent />
    </Layout>
  );
  
  export default EventsPageBnb;
  